<template>
    <div class="banner-rain">
        <div class="rain-text">
            <div class="text-header">
                JACKPOT
                <button v-on:click="rainSetDropdownTip(true)" class="button-tip">
                    
                </button>
            </div>
            <div class="text-info">
                Jackpot <span>her saniye 3 birim artıyor</span>! Oynayarak pota katkıda bulunun!
            </div>
            <div class="text-bottom">
                <div class="bottom-amount">
                    <img src="@/assets/img/icons/coin.svg" alt="icon" />
                    <div class="amount-value">
    <span :key="displayJackpot">{{ displayJackpot }}</span>
</div>
                </div>
            </div>
        </div>
        <img src="@/assets/img/banner-rain.png" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import IconRain from '@/components/icons/IconRain';
import IconTimerGradient from '@/components/icons/IconTimerGradient';

export default {
    name: 'BannerRain',
    components: {
        IconRain,
        IconTimerGradient
    },
    data() {
        return {
            jackpotValue: 1000.00, // Başlangıç Jackpot değeri
            displayJackpot: 1000.00, // Ekranda görünen Jackpot değeri
            incrementAmount: 3, // Her saniyede 3 birim artacak
            incrementDuration: 1000, // 1 saniyede artış tamamlanacak
        };
    },
    methods: {
        ...mapActions(['rainSetDropdownTip']),

        animateJackpotIncrease() {
    const startValue = typeof this.displayJackpot === 'string' 
        ? parseFloat(this.displayJackpot.replace(/,/g, '')) 
        : this.displayJackpot; // displayJackpot her zaman sayı olmalı

    const endValue = this.jackpotValue;
    const duration = this.incrementDuration;
    const startTime = performance.now();

    const animate = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1); // 0'dan 1'e kadar bir oranda animasyon
        const currentValue = startValue + (endValue - startValue) * progress;

        // Sayıyı formatla ve ekrana yaz
        this.displayJackpot = currentValue.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });

        if (progress < 1) {
            requestAnimationFrame(animate); // Animasyon devam edecek
        }
    };

    requestAnimationFrame(animate);
},


jackpotStartTimer() {
    let savedValue = localStorage.getItem('jackpotValue');
    if (savedValue) {
        this.jackpotValue = parseFloat(savedValue);
        this.displayJackpot = this.jackpotValue; // Jackpot başlangıç değeri bir sayı olmalı
    }

    // Jackpot artışını başlat
    this.jackpotInterval = setInterval(() => {
        this.jackpotValue += this.incrementAmount;
        if (this.jackpotValue >= 400000) {
            this.jackpotValue = 1000; // 400.000'e ulaştığında sıfırlanacak
        }
        localStorage.setItem('jackpotValue', this.jackpotValue);

        // Görünür değeri yumuşak bir şekilde güncelle
        this.animateJackpotIncrease();
    }, this.incrementDuration);
},
},
    computed: {
        ...mapGetters(['generalRain']),
    },
    beforeDestroy() {
        clearInterval(this.jackpotInterval); // Sayaç intervalini temizle
    },
    created() {
        this.jackpotStartTimer(); // Sayfa yüklendiğinde sayaç başlatılır
    }
}
</script>

<style scoped>
    .banner-rain {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 40px 20px 25px;
        background: linear-gradient(100deg, rgba(2, 243, 127, 0.74) 0%, rgba(2, 216, 113, 0.65) 7%, rgba(0, 0, 0, 0) 50%),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                    radial-gradient(110% 110% at 50% 30%, #005e3e 0%, #021729 100%);
        overflow: hidden;
        z-index: 1;
        border-radius: 15px;
    }

    .banner-rain img {
        width: 115px;
        
    }
    .jackpot-increase-enter-active, .jackpot-increase-leave-active {
    transition: none; /* Animasyonu kaldır */
}

.jackpot-increase-enter, .jackpot-increase-leave-to {
    opacity: 1; /* Geçiş yerine sabit değer */
}
    .banner-rain .rain-text {
        width: calc(100% - 115px);
        padding-right: 40px;
    }

    .banner-rain .text-header {
        display: flex;
        align-items: center;
        line-height: 48px;
        font-size: 40px;
        font-weight: 900;
        color: #ffffff;
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2), 0px 3px 0px #1c4d3e;
    }

    .banner-rain .text-info {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #a6d6c8;
        font-family: 'Rubik';
    }

    .banner-rain .text-info span {
        color: #01f4b7;
    }

    .banner-rain .text-bottom {
        margin-top: 15px;
        display: flex;
    }

    .banner-rain .bottom-amount {
        background: rgba(143, 103, 5, 0.2);
        border: 0.5px solid #ffcc51;
        font-size: 10px;
        font-weight: 600;
        color: #bbbfd0;
        padding: 5px 10px;
        border-radius: 8px;
        display: flex;
        align-items: center;
    }

    .banner-rain .bottom-amount img {
        width: 17px;
        height: 17px;
        margin-right: 8px;
    }
    
    .banner-rain::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('~@/assets/img/banner-rain-bg.png');
        mix-blend-mode: luminosity;
        opacity: 0.5;
        z-index: -1;
    }

    .banner-rain .amount-value {
        font-size: 20px;
        font-weight: 900;
        color: #ffffff;
    }
    .banner-rain .text-header button.button-tip .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 800;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.13) -90%, rgba(0, 33, 24, 0.15) -40%, rgba(0, 99, 73, 0.15) 230%, rgba(1, 193, 143, 0.1) 65%, rgba(1, 237, 176, 0.06) 100%), #0d262c;
        clip-path: polygon(5px 0, calc(100% - 5px) 0, 100% 25%, 100% 75%, calc(100% - 5px) 100%, 5px 100%, 0 75%, 0 25%);
    }

    .banner-rain .text-header button.button-tip .button-inner svg {
        width: 17px;
        margin-right: 8px;
    }

    @media only screen and (max-width: 600px) {

        .banner-rain  {
   border-radius: 15px;
    
}
.banner-rain img {
    width: 60px;
    
}

.banner-rain .rain-text {
    width: 100%;
    padding-right: 0;
}
}

    
</style>
