<template>
    <div class="casino-play">
      <div v-if="!gameUrl" class="loading-message">Loading the game...</div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  
  export default {
    name: 'CasinoPlay',
    data() {
      return {
        gameUrl: '',
        error: null,
      };
    },
    computed: {
      ...mapGetters(['authUser']),
    },
    async created() {
      const gameCode = this.$route.query.game_code; // URL'den game_code alınır
      const providerCode = this.$route.query.provider_code; // URL'den provider_code alınır
      const userId = this.authUser.user._id;
      const username = this.authUser.user.username;
  
      console.log('Game Code:', gameCode);
      console.log('Provider Code:', providerCode);
      console.log('User ID:', userId);
  
      if (!gameCode || !providerCode) {
          this.error = 'Invalid game or provider information.';
          return;
      }
  
      try {
          // Oyun başlatma isteği yapılır
          const response = await axios.post('https://api.rivobit.com/gold_api', {
              method: 'game_launch',
              agent_code: 'burry_NGN', // .env dosyasından ya da sabit olarak belirleyebilirsiniz
              agent_token: 'b4d897dfcac800dcb92eaf904677f67e', // .env dosyasından ya da sabit olarak belirleyebilirsiniz
              user_code: userId,
              provider_code: providerCode,
              game_code: gameCode,
              lang: 'en',
          });
  
          console.log('API Response:', response.data);
  
          if (response.data.status === 1 && response.data.launch_url) {
              this.gameUrl = response.data.launch_url;
              console.log('Launch URL:', this.gameUrl);
              // Oyunu yeni bir sekmede aç
              window.open(this.gameUrl, '_blank');
          } else {
              this.error = 'Unable to launch the game';
              console.log('Error:', response.data.msg);
          }
      } catch (error) {
          console.error('Error launching game:', error);
          this.error = 'Error launching game';
      }
    },
  };
  </script>
  
  <style scoped>
  .casino-play {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loading-message {
    font-size: 24px;
    color: white;
  }
  </style>
  