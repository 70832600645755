<template>
    <div class="admin-user-edit">
      <h2 class="title">Edit User Information</h2>
      <form @submit.prevent="updateUser">
        <div class="form-group">
          <label for="username">Username</label>
          <input type="text" v-model="user.username" id="username" required />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" v-model="user.local.email" id="email" required />
        </div>
        <div class="form-group">
          <label for="phone">Phone</label>
          <input type="tel" v-model="user.phone" id="phone" required />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" v-model="password" id="password" placeholder="Leave blank to keep current password" />
        </div>
        <div class="form-group">
          <label for="rank">Rank</label>
          <select v-model="user.rank" id="rank" required>
            <option value="user">User</option>
            <option value="partner">Partner</option>
            <option value="mod">Mod</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <button type="submit" class="submit-button">Update User</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        user: {
          username: '',
          local: { email: '' },
          phone: '',
          rank: 'user',
        },
        password: '',
      };
    },
    computed: {
      ...mapGetters(['modalsData']), // Fetching modalsData for user ID
    },
    mounted() {
      this.getUser();
    },
    methods: {
      async getUser() {
        try {
          const response = await axios.get(`http://192.168.1.111:5000/users/${this.modalsData.user._id}`);
          this.user = response.data;
        } catch (error) {
          console.error('Error fetching user:', error);
        }
      },
      async updateUser() {
        const updateData = { ...this.user };
        if (this.password) {
          updateData.local.password = this.password; // Password will be encrypted on the backend
        }
  
        try {
          await axios.put(`http://192.168.1.111:5000/users/${this.modalsData.user._id}`, updateData);
          alert('User updated successfully!');
        } catch (error) {
          console.error('Error updating user:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .admin-user-edit {
    
    
    font-family: Arial, sans-serif;
   
    border-radius: 8px;
    
  }
  
  .title {
    text-align: center;
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #bbbfd0;
  }
  
  input,
  select {
    width: 100%;
    padding: 10px;
    background: #1a4f63;
    border-radius: 4px;
    font-size: 16px;
    color: white;
  }
  
  button.submit-button {
    width: 100%;
    padding: 10px;
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
 
  </style>
  