<template>
    <transition v-bind:name="homeGetTransition">
        <div v-if="position === index" class="home-news-element">
            <keep-alive>
                <!-- Banner bileşenleri sıralı olarak ekleniyor -->
                <component v-bind:is="'Banner' + (banner === 'JackPot' ? 'Jackpot' : banner === 'Tournament' ? 'Tournament' : banner)"></component>
            </keep-alive>
        </div>
    </transition>
</template>

<script>
    import BannerRain from '@/components/banner/BannerRain';
    import BannerJackpot from '@/components/banner/BannerJackpot';
    import BannerTournament from '@/components/banner/BannerTournament';

    export default {
        name: 'HomeNewsBanner',
        components: {
            BannerRain,
            BannerJackpot,
            BannerTournament
        },
        props: ['banner', 'direction', 'position', 'index'],
        data() {
            return {
                currentBanner: 0, // Şu anki bannerın indexi
                banners: ['Rain', 'JackPot', 'Tournament'], // Bannerlar listesi
                intervalId: null // Zamanlayıcı
            };
        },
        computed: {
            homeGetTransition() {
                return 'slider-fade'; // Geçiş animasyonu ismi
            },
            banner() {
                return this.banners[this.currentBanner]; // Sırasıyla bannerı getir
            }
        },
        mounted() {
            this.startBannerRotation(); // Sayfa yüklendiğinde başlat
        },
        beforeDestroy() {
            clearInterval(this.intervalId); // Bileşen yok olduğunda interval'i temizle
        },
        methods: {
            startBannerRotation() {
                // 5 saniyede bir banner değiştirme işlemi
                this.intervalId = setInterval(() => {
                    this.currentBanner = (this.currentBanner + 1) % this.banners.length;
                }, 5000); // 5 saniyede bir banner değiştir
            }
        }
    }
</script>

<style scoped>
    .home-news-element {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    /* Geçiş animasyonları */
    .slider-fade-enter-active,
    .slider-fade-leave-active {
        transition: opacity 0.5s ease, transform 0.5s ease; /* Animasyon süresi ve tipi */
    }

    .slider-fade-enter, .slider-fade-leave-to /* .slider-fade-leave-active for below version 2.1.8 */ {
        opacity: 0; /* Saydamlık başlangıç ve bitiş */
        transform: scale(1.05); /* Biraz büyüterek görsel etki */
    }

    /* Sol ve sağ kaydırma için animasyonlar */
    .home-news-element.slider-left-enter-active,
    .home-news-element.slider-left-leave-active,
    .home-news-element.slider-right-enter-active,
    .home-news-element.slider-right-leave-active {
        transition: all 0.5s ease;
    }

    .home-news-element.slider-left-enter {
        transform: translateX(-100%);
    }

    .home-news-element.slider-left-leave-to {
        transform: translateX(100%);
    }

    .home-news-element.slider-right-enter {
        transform: translateX(100%);
    }

    .home-news-element.slider-right-leave-to {
        transform: translateX(-100%);
    }
</style>
