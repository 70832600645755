<template>
    <nav id="navbar">
        <div class="navbar-left">
         
         
            <div  class="link-home2" >
    <div class="link-inner2" @click="toggleSidebar">
        <svg width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
 
  <path fill="#6e95b6" d="M3 3h8v8H3V3zm10 0h8v8h-8V3zM3 13h8v8H3v-8zm10 0h8v8h-8v-8z"/>
</svg>
    </div>
  </div>
           
            <router-link to="/" class="link-logo">
                <img src="@/assets/img/logo.png" />
            </router-link>
            <router-link to="/rewards" class="link-rewards">
                    <img src="@/assets/img/chest_coins.png" />
                    <span>CLAIM YOUR</span>
                    <span>REWARDS</span>
                </router-link>
        </div>

        <div class="navbar-mid">
            <AuthButton v-if="authUser.user === null" />
            <NavbarCashier v-else />
        </div>

        <div class="navbar-right">
            <AuthButton v-if="authUser.user === null" />
            <div v-else class="right-auth">
              
                <NavbarUserDropdown />
                <div @click="toggleNotificationsModal" class="notification-icon">
        <img src="@/assets/img/bell.png" alt="Notifications" />
      </div>

            </div>
          
            
        </div>
  <!-- Yeni eklenen bildirim simgesi -->
   <!-- Yeni eklenen bildirim simgesi -->
  
      <!-- Bildirim Modalı -->
      <div v-if="showNotificationsModal" class="modal-overlay" @click.self="toggleNotificationsModal">
        <div class="modal-content">
          <button @click="toggleNotificationsModal" class="close-button">×</button>
          <h2>Bildirimler</h2>

          <!-- Yükleniyor Göstergesi -->
          <p v-if="loading" class="loading-message">Yükleniyor...</p>

          <!-- Hata Mesajı -->
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

          <!-- Bildirim Listesi -->
          <ul v-if="!loading && notifications.length" class="notification-list">
            <li v-for="notification in notifications" :key="notification._id" class="notification-item">
              <h3>{{ notification.title }}</h3>
              <p>{{ notification.message }}</p>
              <span class="notification-date">{{ formatDate(notification.createdAt) }}</span>
            </li>
          </ul>

          <!-- Bildirim Yok Mesajı -->
          <p v-else-if="!loading && !notifications.length" class="no-notifications">
            Henüz bir bildiriminiz yok.
          </p>
        </div>
      </div>
     
       
    </nav>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';
    import IconChatGradient from '@/components/icons/IconChatGradient';
    import IconNavbarGradient from '@/components/icons/IconNavbarGradient';
    import IconHome from '@/components/icons/IconHome';
    import IconUser from '@/components/icons/IconUser';
    import IconVault from '@/components/icons/IconVault';
    import IconRakeback from '@/components/icons/IconRakeback';
    import IconAffiliates from '@/components/icons/IconAffiliates';
    import IconLeaderboard from '@/components/icons/IconLeaderboard';
    import IconSignOut from '@/components/icons/IconSignOut';
    import IconCrash from '@/components/icons/IconCrash';
    import IconBlackjack from '@/components/icons/IconBlackjack';
    import IconRoll from '@/components/icons/IconRoll';
    import IconDuels from '@/components/icons/IconDuels';
    import AuthButton from '@/components/AuthButton';
    import NavbarHomeDropdown from '@/components/navbar/NavbarHomeDropdown';
    import NavbarCashier from '@/components/navbar/NavbarCashier';
    import NavbarUserDropdown from '@/components/navbar/NavbarUserDropdown';
    import ModalNotifications from '@/components/modals/ModalNotifications'; // Bildirim modalı bileşeni
    import axios from 'axios';
    export default {
        name: 'Navbar',
        components: {
            IconChatGradient,
            IconNavbarGradient,
            IconHome,
            IconUser,
            IconVault,
            IconRakeback,
            IconAffiliates,
            IconLeaderboard,
            IconSignOut,
            IconCrash,
            IconBlackjack,
            IconRoll,
            IconDuels,
            AuthButton,
            NavbarHomeDropdown,
            NavbarCashier,
            NavbarUserDropdown,
           
        },
        data() {
    return {
      showNotificationsModal: false,
      notifications: [],
      loading: false,
      errorMessage: ''
    };
  },
        methods: {
            toggleNotificationsModal() {
      this.showNotificationsModal = !this.showNotificationsModal;
      if (this.showNotificationsModal) {
        this.fetchNotifications(); // Modal açıldığında bildirimleri getir
      }
    },
    async fetchNotifications() {
      this.loading = true;
      this.errorMessage = '';
      try {
        const response = await axios.get(`https://play.rivobit.com/notices/${this.authUser.user._id}`);
        this.notifications = response.data;
      } catch (error) {
        console.error('Error fetching notifications:', error);
        this.errorMessage = 'Bildirimler yüklenirken bir hata oluştu.';
      } finally {
        this.loading = false;
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
            ...mapActions([
        'notificationShow', 
        'modalsSetShow', 
        'generalSetSidebarMobile', 
        'authLogoutUser',
        'toggleSidebar' // Sidebar açma/kapama fonksiyonunu Vuex'ten alıyoruz
    ]),
            navbarVaultButton() {
                this.modalsSetShow('Vault');
                this.generalSetSidebarMobile(null);
            }
        },
        computed: {
            ...mapGetters([
                'generalSidebarMobile', 
                'authUser'
            ])
        }
    }
</script>

<style scoped>
    nav#navbar {
        width: 100%;
        height: 70px;
        position: fixed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 0;
        left: 0;
        padding: 0 10px;
        background: rgba(13, 49, 78, 0.36);
        z-index: 50;
    }

    nav#navbar .navbar-left,
    nav#navbar .navbar-right  {
        width: calc(50% - 176px);
        display: flex;
        align-items: center;
        z-index: 1;
    }

    nav#navbar .navbar-right  {
        justify-content: flex-end;
    }

  

    nav#navbar .navbar-left a.link-logo {
        display: flex;
        align-items: center;
        margin-right: 30px;
    }

    nav#navbar .navbar-left a.link-logo img {
        height: 41px;
        width: 100%;
    }

    nav#navbar .navbar-left a.link-home {
        width: 50px;
        height: 50px;
        position: relative;
        margin-right: 10px;
        padding: 1px;
        z-index: 1;
    }

    nav#navbar .navbar-left a.link-home::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 133, 255, 0) 0%, #328adb 100%);
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        z-index: -1;
    }

    nav#navbar .navbar-left a.link-home::after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        background: #061628;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        z-index: -1;
    }

    nav#navbar .navbar-left a.link-home .link-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(255deg, rgba(59, 126, 183, 0.5) -70%, rgba(20, 80, 129, 0.5) 90%);
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        transition: background 0.3s ease;
    } 

    nav#navbar .navbar-left a.link-home:hover .link-inner {
        background: linear-gradient(255deg, #3b7eb7 -70%, #145081 90%);
    }

    nav#navbar .navbar-left a.link-home .link-inner svg {
        fill: #6e95b6;
        transition: fill 0.3s ease;
    }

    nav#navbar .navbar-left a.link-home:hover .link-inner svg {
        fill: #ffffff;
    }

    nav#navbar .navbar-mid button.auth-button {
        display: none;
    }

    nav#navbar .right-auth {
        display: flex;
        align-items: center;
    }

    nav#navbar a.link-rewards {
        width: 155px;
        height: 45px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-right: 25px;
        padding: 0 15px 0 50px;
        border-radius: 5px;
        background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
    }

    nav#navbar a.link-rewards img {
        width: 71px;
        position: absolute;
        top: 50%;
        left: -15px;
        transform: translate(0, -50%);
        filter: drop-shadow(0px 0px 4px rgba(255, 245, 59, 0.35));
    }

    nav#navbar a.link-rewards span {
        font-family: 'Rubik';
        font-size: 14px;
        font-weight: 900;
        background: linear-gradient(180deg, #ffffff 1.75%, rgba(108, 255, 211, 0.85) 35%, rgba(0, 255, 71, 0.55) 70.93%, #7bff90 95%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        filter: drop-shadow(0px 0px 10px rgba(255, 10, 10, 0.25));
    }

    nav#navbar .navbar-right button.button-toggle {
        width: 36px;
        height: 28px;
        display: none;
        filter: drop-shadow(0px 1.8px 1.8px rgba(0, 0, 0, 0.10));
    }

    nav#navbar .navbar-right button.button-toggle .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #214059;
        clip-path: polygon(4px 0, calc(100% - 4px) 0, 100% 25%, 100% 75%, calc(100% - 4px) 100%, 4px 100%, 0 75%, 0 25%);
    }

    nav#navbar .navbar-right button.button-toggle .button-inner svg {
        fill: #5b7b95;
        transition: fill 0.3s ease;
    }

    nav#navbar .navbar-right button.button-toggle:hover .button-inner svg {
        fill: #ffffff;
    }

    nav#navbar .navbar-mobile {
    width: 60vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%); /* Başlangıçta gizli */
    padding: 95px 21px 0 21px;
    overflow-y: scroll;
    background-color: #021423;
    z-index: 999; /* En üstte görüntülenmesi için */
    transition: transform 0.3s ease;
}

    nav#navbar .navbar-mobile a,
    nav#navbar .navbar-mobile button {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
        color: #bbbfd0;
        border-bottom: 1px solid #102b3f;
    }

    nav#navbar .navbar-mobile .mobile-user a,
    nav#navbar .navbar-mobile .mobile-games a,
    nav#navbar .navbar-mobile .mobile-user button {
        margin-top: 25px;
    }

    nav#navbar .navbar-mobile a.router-link-exact-active,
    nav#navbar .navbar-mobile a.link-blackjack.router-link-active {
        background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    nav#navbar .navbar-mobile a::before,
    nav#navbar .navbar-mobile button::before {
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #102b3f;
    }

    nav#navbar .navbar-mobile a svg,
    nav#navbar .navbar-mobile button svg {
        width: 13px;
        margin-right: 8px;
        fill: #bbbfd0;
    }

    nav#navbar .navbar-mobile a.router-link-exact-active svg,
    nav#navbar .navbar-mobile a.link-blackjack.router-link-active svg {
        fill: #00ffc2;
    }

    nav#navbar .mobile-games {
        margin-top: 40px;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
    }

    @media only screen and (max-width: 1750px) {

        nav#navbar {
            padding: 0 10px;
        }

    }

    @media only screen and (max-width: 1650px) {

        nav#navbar .left-rakeback,
        nav#navbar .right-links {
            display: none;
        }
       

    }

    @media only screen and (max-width: 1500px) {

        nav#navbar {
            width: 100%;
        }

    }

    @media only screen and (max-width: 1175px) {

        nav#navbar {
            
            padding: 0 20px;
            background: rgba(9, 40, 65, 0.48);
            border-bottom: 1px solid rgba(27, 64, 92, 0.35);
        }

        nav#navbar .navbar-left, 
        nav#navbar .navbar-right {
            width: auto;
        }

        nav#navbar .navbar-left button.button-chat,
        nav#navbar .navbar-right button.button-toggle {
            display: none;
        }
        
       
        nav#navbar .navbar-right button.auth-button,
        nav#navbar .right-auth {
            display: flex;
        }

        nav#navbar a.link-rewards,
        nav#navbar .navbar-left a.link-logo,
        nav#navbar .navbar-left a.link-home,
        nav#navbar .navbar-left .navbar-home-dropdown
        nav#navbar .navbar-right .notification-icon
         {
            display: none;
        }

        nav#navbar .navbar-mid button.auth-button {
            display: block;
        }

        nav#navbar .navbar-mobile.mobile-open {
            transform: translate(0, 0);
        }

    }
    .sidebar {
  position: fixed;
  left: 0;
  top: 87px;
  height: 100vh;
  width: 16.375rem; /* Sidebar'ın tam genişliği */
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease;
  background: linear-gradient(180deg, #062641 0%, #031422 140%);
  backdrop-filter: blur(0.625rem);
  border-radius: 0.9375rem;
  margin: 0.9375rem;
  height: calc(90% - 1.875rem);
}

.category-path {
    fill: #6e95b6; /* Varsayılan renk */
    transition: fill 0.3s ease;
  }

  .category-button:hover .category-path {
    fill: #ffffff; /* Hover olduğunda renk */
  }

  .link-home2 {
    width: 50px;
    height: 50px;
    position: relative;
    margin-right: 10px;
    padding: 1px;
    z-index: 1;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 133, 255, 0) 0%, #328adb 100%);
    -webkit-clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
    clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
    content: '';
  }
  .link-inner2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(255deg, rgba(59, 126, 183, 0.5) -70%, rgba(20, 80, 129, 0.5) 90%);
    -webkit-clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
    clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
    transition: background 0.3s ease;
  }
  .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 30px 30px 30px;
    border-radius: 15px;
    background: radial-gradient(100% 100% at 50% 0%, rgba(0, 255, 194, 0.15) 0%, rgba(7, 38, 61, 0) 80%), linear-gradient(256deg, #07263d 0%, #07243a 100%);
    width: 400px;
}

.notification-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 20px;
}

.notification-icon img {
  width: 100%;
  height: auto;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #6c757d;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #000000;
}

h2 {
  margin-top: 0;
  font-size: 24px;
  background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.notification-item {
  background-color: #1a4f63;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  width: 300px;
}

.notification-item:hover {
  background-color: #1a4f63;
}

.notification-item h3 {
  margin: 0;
  font-size: 18px;
  color: #bbbfd0;
}

.notification-item p {
  margin: 5px 0;
  font-size: 14px;
  color: #bbbfd0;
}

.notification-date {
  font-size: 12px;
  color: #888;
}

.loading-message,
.error-message,
.no-notifications {
  font-size: 16px;
  color: #555;
  text-align: center;
}

@media only screen and (max-width: 1175px) {
    .notification-icon {
        display: none; /* Username ve level tamamen gizlenir */
    }

    
}
</style>

