<template>
  <div class="wrapper">
    <button class="new-button" @click="handleButtonClick">
      <img src="@/assets/img/bottom/wallet.gif" alt="new button icon" class="new-button-icon"/>
    </button>
  <div class="bottom-navigation">
    
    <div 
      v-for="(item, index) in items" 
      :key="item.text" 
      :class="['nav-item', { 'middle-button': index === 3, 'selected': selectedItem === item.text }]" 
      @click="navigate(item)"
    >
      <img 
        :src="getIcon(item)" 
        alt="icon" 
        :class="['nav-icon', { 'wallet-icon': item.text === 'Wallet' }]" 
      />
      <span :class="['nav-text', { 'selected-text': selectedItem === item.text }]">{{ item.text }}</span>
    </div>
  </div>
</div>
</template>

  <script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      items: [
        { text: 'Menu', route: '/', icon: require( '@/assets/img/bottom/7.png'), selectedIcon: require('@/assets/img/bottom/1.png') }, 
        { text: 'Home', route: '/', icon: require('@/assets/img/bottom/8.png'), selectedIcon: require('@/assets/img/bottom/2.png') },
        { text: 'Slot', route: '/category/slots', icon: require('@/assets/img/bottom/9.png'), selectedIcon: require('@/assets/img/bottom/3.png') },
        { text: 'Wallet', route: '/deposit', icon: require('@/assets/img/bottom/wallet.gif'), selectedIcon: require('@/assets/img/bottom/wallet.gif') },
        { text: 'Sports', route: '/sports', icon: require('@/assets/img/bottom/10.png'), selectedIcon: require('@/assets/img/bottom/4.png') },
        { text: 'Vault', route: '/rewards', icon: require('@/assets/img/bottom/11.png'), selectedIcon: require('@/assets/img/bottom/5.png') },
        { text: 'Chat', route: '/chat', icon: require('@/assets/img/bottom/12.png'), selectedIcon: require('@/assets/img/bottom/6.png') }
      ],
      selectedItem: 'Home' // Varsayılan olarak ilk buton seçili
    };
  },
  methods: {
    ...mapActions([
      'generalSetSidebarMobile',
      'modalsSetShow'
    ]),
    handleButtonClick() {
    // Wallet butonundaki fonksiyonu kopyalıyoruz
    this.modalsSetShow('Cashier');
  },
    navigate(item) {
      if (this.selectedItem === item.text) {
        // İkinci kez tıklama durumunda
        if (item.text === 'Chat' || item.text === 'Menu') {
          this.generalSetSidebarMobile(null); // Sidebar'ı kapat
        } else if (item.text === 'Wallet' || item.text === 'Vault') {
          this.modalsSetShow(null); // Modal'ı kapat
        }
        this.selectedItem = null; // Seçili öğeyi temizle
      } else {
        // İlk kez tıklama veya farklı bir öğeye tıklama durumu
        this.selectedItem = item.text; // Seçili öğeyi güncelle
        if (item.text === 'Chat') {
          this.generalSetSidebarMobile('Chat');
        } else if (item.text === 'Menu') {
          this.generalSetSidebarMobile('Navbar');
        } else if (item.text === 'Wallet') {
          this.modalsSetShow('Cashier');
        } else if (item.text === 'Vault') {
          this.navbarVaultButton();
        } else {
          this.$router.push(item.route);
        }
      }
    },
    getIcon(item) {
      return this.selectedItem === item.text ? item.selectedIcon : item.icon;
    },
    navbarVaultButton() {
      this.modalsSetShow('Vault');
      this.generalSetSidebarMobile(null);
    }
  }
};
</script>


  
  <style>
  .bottom-navigation {
    border-width: 1px 0 0;
    display: flex;
    height: 58px;
    justify-content: space-between;
    padding: 10px 12px;
    align-items: center;
    transition: border-radius .1s ease-in-out;
    z-index: 9999;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgb(9 40 65 / 95%);
    font-family: 'Rubik';
    color: #6a88a1;
    border-radius: 12px 12px 0 0;
    clip-Path: polygon(49.87% 50%, 50.41% 49.88%, 51.47% 49%, 52.48% 47.27%, 53.44% 44.75%, 54.32% 41.53%, 55.13% 37.65%, 55.86% 33.15%, 56.48% 28.12%, 56.76% 25.42%, 57.23% 20.68%, 58.36% 11.82%, 59.35% 6.25%, 60.08% 3.35%, 60.86% 1.27%, 61.7% 0.15%, 62.13% 0%, 95.73% 0%, 96.17% 0.13%, 97% 1.2%, 97.77% 3.22%, 98.45% 6.08%, 99.03% 9.7%, 99.49% 13.95%, 99.81% 18.73%, 99.98% 23.93%, 100% 26.67%, 100% 100%, 0% 100%, 0% 26.67%, 0.02% 23.93%, 0.19% 18.73%, 0.51% 13.95%, 0.97% 9.7%, 1.55% 6.08%, 2.23% 3.22%, 3% 1.2%, 3.83% 0.13%, 4.27% 0%, 37.6% 0%, 38.03% 0.15%, 38.87% 1.27%, 39.65% 3.35%, 40.38% 6.25%, 41.37% 11.82%, 42.5% 20.68%, 42.98% 25.42%, 43.25% 28.12%, 43.88% 33.15%, 44.6% 37.65%, 45.41% 41.53%, 46.3% 44.75%, 47.25% 47.27%, 48.26% 49%, 49.32% 49.88%, 49.87% 50%, 49.87% 50%);
  }

  .new-button {
    position: fixed;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 75%);
    box-shadow: rgba(35, 255, 119, 0.5) 0px 3px 16px, rgba(255, 255, 255, 0.3) 0px 4px 3px inset;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
@media (min-width: 768px) {
    .new-button {
      display: none;
    }
  }
  
  .middle-button {
  position: relative;
  margin-top: -15px;
  
  color: #6a88a1;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(35, 255, 119, 0.5) 0px 3px 16px, rgba(255, 255, 255, 0.3) 0px 4px 3px inset;
  transition: top .1s ease-in-out;
  clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%); /* Altıgen şekil */
}

  
  .bottom-navigation::before {
    content: "";
    position: absolute;
    top: -30px; /* Kavis yüksekliği */
    left: 50%;
    transform: translateX(-50%);
    width: 60px; /* Kavis genişliği */
    height: 60px;
    
    border-radius: 50%;
    z-index: -1; /* Navigasyon çubuğunun arkasında kalması için */
  }
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    position: relative;
  }
  
  .nav-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
  }

  .wallet-icon {
  width: 31px;
  height: 31px;
}
  
  .nav-text {
    font-size: 12px;
  }
  
  .selected-text {
    color: #00ffc2; /* Seçili butonun altındaki yazının rengi */
  }
  
  .selected::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 4px;
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
    border-radius: 5px;
    box-shadow: 0 0 10px #00ffc2 , 0 0 20px #00ffc2 , 0 0 30px #00ffc2 , 0 0 40px #00ffc2;
  }
  
  @media (min-width: 768px) {
    .bottom-navigation {
      display: none;
    }
  }
  </style>
  