import { render, staticRenderFns } from "./ModalJackpot.vue?vue&type=template&id=70b63421&scoped=true"
import script from "./ModalJackpot.vue?vue&type=script&lang=js"
export * from "./ModalJackpot.vue?vue&type=script&lang=js"
import style0 from "./ModalJackpot.vue?vue&type=style&index=0&id=70b63421&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b63421",
  null
  
)

export default component.exports