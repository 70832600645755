<template>
  <div class="customer-services-page">
    <h1>Customer Services</h1>
    <div class="services-list">
      <!-- Dinamik müşteri hizmetleri listesi -->
      <div class="service-item" v-for="service in customerServices" :key="service._id">
        <h2>{{ service.title }}</h2>
        <p>{{ service.platform }}</p>
        <p> {{ service.workingHours }}</p>
        <a :href="service.link" target="_blank">
          <button class="service-btn">Contact Now</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      customerServices: [] // Tüm müşteri hizmetleri bilgileri burada saklanacak
    };
  },
  mounted() {
    this.fetchCustomerServices(); // Sayfa yüklendiğinde müşteri hizmetlerini çek
  },
  methods: {
    async fetchCustomerServices() {
      try {
        const response = await axios.get("https://play.rivobit.com/customerservices"); // Backend'den müşteri hizmetlerini çek
        this.customerServices = response.data; // Gelen veriyi customerServices değişkenine ata
      } catch (error) {
        console.error("Müşteri hizmetleri alınırken hata oluştu:", error);
      }
    }
  }
};
</script>

<style scoped>
.customer-services-page {
  padding: 20px;
  color: #fff;
  font-family: 'Rubik', sans-serif;
  text-align: center;
}

h1 {
  font-size: 28px;
  font-weight: 900;
  background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services-list {
  display: flex;
  
  flex-wrap: wrap;
  margin-top: 30px;
}

.service-item {
  width: 300px;
  padding: 20px;
  background: radial-gradient(100% 100% at 50% -30%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(255deg, #07263d 0%, #07243a 100%);
  border-radius: 15px;
  margin: 10px;
  text-align: center;
}

.service-item h2 {
  font-size: 18px;
  font-weight: 900;
  background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-item p {
  font-size: 14px;
  color: #ffffff;
}

.service-btn {
  background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
  color: #fff;
  font-weight: 900;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.service-btn:hover {
  background-color: #00aa6d;
}
</style>
