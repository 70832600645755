var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.generalSettings === null || (_vm.authToken !== null && _vm.authUser.user === null))?_c('AppLoader',{key:"loading"}):(_vm.generalSettings.general.maintenance.enabled === false)?_c('div',{key:"page",staticClass:"app-page"},[_c('Chat'),_c('Navbar'),(!_vm.isAdminRoute)?[_c('Sidebar',{on:{"open-jackpot-modal":_vm.openJackpotModal}})]:_vm._e(),(_vm.isJackpotModalOpen)?_c('ModalJackpot',{on:{"close":_vm.closeJackpotModal}}):_vm._e(),_c('main',{ref:"mainContainer",class:{
          'main-background': _vm.appHasBackground === true, 
          'main-shifted-sidebar': _vm.isSidebarClosed,  // Sidebar kapalıyken içerik sola kayar
          'main-expanded-sidebar': !_vm.isSidebarClosed,  // Sidebar açıkken içerik genişler
          'main-shifted': _vm.generalSidebarMobile === 'Chat',
          'main-expanded': _vm.generalSidebarMobile !== 'Chat',
        }},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('router-view')],1),(!_vm.isAdminRoute)?[_c('Footer')]:_vm._e()],2),_c('BottomNavigation'),_vm._v(" "),_c('Modals'),_c('Notifications')],2):_c('AppMaintenance',{key:"maintenance"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }