<template>
    <div>
        <h1>{{ game.name }}</h1>
        <img :src="game.img" alt="Game Image">
        <p>Sağlayıcı: {{ game.provider_code }}</p>
        <p>Oyun Kodu: {{ game.game_code }}</p>
        <!-- Oyunla ilgili diğer bilgiler -->
    </div>
</template>

<script>
import axios from 'axios'; // axios ile backend'den veri çekiyoruz

export default {
    data() {
        return {
            game: {}  // Oyunun bilgilerini tutacak
        };
    },
    async created() {
        const gameId = this.$route.params.id;  // Rota parametresinden oyun ID'sini al
        try {
            const response = await axios.get(`/api/games/${gameId}`);
            this.game = response.data;
        } catch (error) {
            console.error("Oyun detayları alınamadı:", error);
        }
    }
}
</script>
