<template>
    <div class="home">
        <div class="home-banner">
            <HomeBannerUser />
        </div>

        <div class="provider-filter">
  <div class="dropdown">
    <button class="dropdown-toggle" @click="toggleDropdown">
      <img src="@/assets/img/provider.png" alt="Provider Icon" class="dropdown-icon" />
      <span class="dropdown-text">
        {{ selectedProvider ? selectedProvider.provider_code : 'Select Provider' }}
      </span>
     
    </button>
    <div v-if="isDropdownOpen" class="dropdown-menu">
      <!-- Search Input -->
      <div class="dropdown-search">
        <img src="@/assets/img/search.png" alt="Search Icon" class="search-icon" />
        <input
          type="text"
          class="search-input"
          v-model="searchQuery"
          placeholder="Search Provider"
        />
      </div>
      <!-- Filtered Provider List -->
      <div
        v-for="(provider, index) in filteredProviders"
        :key="index"
        class="dropdown-item"
        @click="selectProvider(provider)"
      >
        <input
          type="checkbox"
          :checked="provider.provider_code === selectedProvider?.provider_code"
        />
        <span>{{ provider.provider_code }}</span>
        <span class="provider-count">({{ provider.count }})</span>
      </div>
    </div>
  </div>
</div>



  <div class="game-search-container">
  
  </div>


  <div class="game-search-container">
    <!-- Search Game Input -->
    <div class="game-search-box">
      <img src="@/assets/img/search.png" alt="Search Icon" class="game-search-icon" />
      <input
        type="text"
        class="game-search-input"
        v-model="gameSearchQuery"
        placeholder="Start entering the game's name or theme"
        @focus="showSearchResult"
        @input="debouncedSearchGames"
      />
    </div>

    <!-- Search Results -->
    <div v-if="isSearchResultVisible" class="game-search-results">
      <!-- Search Result Header -->
      <div class="game-search-header">
        <img src="@/assets/img/search.png" alt="Result Icon" />
        <span>Search Results</span>
        <button class="game-search-close-button" @click="closeSearch">X</button>
      </div>

      <!-- Search Instructions or Results -->
      <div class="game-search-content">
        <div v-if="!gameSearchResults.length">
          <p v-if="!gameSearchStarted">Enter at least 3 symbols to start searching</p>
          <p v-else>No results found.</p>
        </div>
        <div
          v-else
          class="game-search-list"
        >
        <div
          v-for="(game, index) in gameSearchResults"
          :key="index"
          class="game-search-item"
          @click="launchGame(game)" 
        >
            <img :src="game.banner" alt="Game Banner" class="game-search-banner" />
          </div>
        </div>
      </div>

      <!-- Recent Searches -->
      <div v-if="recentSearches.length" class="game-search-recent-searches">
        <div class="game-search-recent-header">
          <img src="@/assets/img/recent.png" alt="Recent Icon" />
          <span>Recent Searches</span>
        </div>
        <div class="game-search-recent-items">
          <span
            v-for="(item, index) in recentSearches"
            :key="index"
            @click="searchRecent(item)"
            class="game-search-recent-item"
          >
            {{ item }}
          </span>
        </div>
      </div>
    </div>
  </div>




 




    <div class="game-category">
  <div class="games-list">
    <div v-for="(game, index) in games" :key="index" class="game-item">
        <div class="game-card" @click="launchGame(game)">
        <img :src="game.banner" alt="Game Banner" class="game-banner" />
      </div>
    </div>
  </div>


  <div v-if="games.length < totalGames" class="see-more-container">
    <button @click="loadMoreGames" class="see-more-button">See More</button>
  </div>
</div>

      
        <HomeDepositMethods />
        <Bets />
    </div>
</template>

<script>
import Bets from '@/components/bets/Bets';
import HomeBannerUser from '@/components/home/HomeBannerUser';
import HomeDepositMethods from '@/components/home/HomeDepositMethods';
import axios from 'axios';
import { mapGetters } from 'vuex';
import debounce from "lodash/debounce";

export default {
    name: 'GameCategory',
    metaInfo: {
        title: 'RivoBIT: The Innovative Crypto Gambling Site'
    },
    components: {
        Bets,
        HomeBannerUser,
        HomeDepositMethods
        
    },
    data() {
        return {
            games: [],
            gamesToShow: 18, // Bir seferde gösterilecek oyun sayısı
            totalGames: 0, // Toplam oyun sayısı
            offset: 0, // Şu anki başlangıç noktası
            providers: [],
            selectedProvider: null,
            isDropdownOpen: false,
            favorites: JSON.parse(localStorage.getItem('favorites')) || [], // Favoriler listesi
            categoryName: this.$route.params.category || 'Games', // URL'den kategori adını al
            searchQuery: "", // Arama için kullanılan model
            gameSearchQuery: "",
      gameSearchResults: [],
      recentSearches: JSON.parse(localStorage.getItem("recentSearches")) || [],
      isSearchResultVisible: false,
      gameSearchStarted: false,
        };
    },
    computed: {
        ...mapGetters(['authUser']), // Vuex store'dan authUser'ı alıyoruz
        categorybutton() {
            return this.buttons.filter(button => 
                button.text.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },

        filteredProviders() {
    // Arama sorgusuna göre provider listesi filtrele
    const query = this.searchQuery.toLowerCase();
    return this.providers.filter(provider =>
      provider.provider_code.toLowerCase().includes(query)
    );
  },
    
        filteredGames() {
    if (this.selectedProvider) {
        return this.games.filter(
            (game) => game.provider_code === this.selectedProvider.provider_code
        );
    }
    return this.games;
},

    sortedProviders() {
      if (!this.selectedProvider) return this.providers;
      // Seçili provider'ı en üste taşır
      return [
        this.selectedProvider,
        ...this.providers.filter(
          (provider) =>
            provider.provider_code !== this.selectedProvider.provider_code
        ),
      ];
    },
      
    },
   
   
    mounted() {
        this.fetchGames();
        
        this.fetchProviders();
    },
    methods: {
        showSearchResult() {
      this.isSearchResultVisible = true;
    },
    closeSearch() {
      this.isSearchResultVisible = false;
    },
    async handleSearchGames() {
  if (this.gameSearchQuery.length < 3) {
    this.gameSearchResults = [];
    this.gameSearchStarted = false;
    return;
  }

  this.gameSearchStarted = true;

  try {
    const response = await axios.get("/search_games", {
      params: { query: this.gameSearchQuery },
    });

    if (response.data.success) {
      this.gameSearchResults = response.data.data;

      // Her bir oyun objesinde `game_code` kontrolü yap
      this.gameSearchResults.forEach((game) => {
        if (!game.game_code) {
          console.warn("Missing game_code in search result:", game);
        }
      });

      // Geçerli aramayı son aramalara ekle
      if (!this.recentSearches.includes(this.gameSearchQuery)) {
        this.recentSearches.push(this.gameSearchQuery);
        localStorage.setItem(
          "recentSearches",
          JSON.stringify(this.recentSearches)
        );
      }
    }
  } catch (error) {
    console.error("Search error:", error);
  }
},

    debouncedSearchGames: debounce(function () {
      this.handleSearchGames();
    }, 300),
    searchRecent(query) {
      this.gameSearchQuery = query;
      this.handleSearchGames();
    },
  
        async fetchProviders() {
    try {
      const category = this.categoryName.toLowerCase();
      const response = await axios.get(`/providers/${category}`);
      if (response.data.success) {
        this.providers = response.data.data;
      } else {
        console.error('Failed to fetch providers:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching providers:', error);
    }
  },

  async fetchGames(reset = false) {
  try {
    const category = this.categoryName.toLowerCase();
    const params = {
      limit: this.gamesToShow,
      offset: this.offset,
      provider_code: this.selectedProvider?.provider_code || null,
    };

    const response = await axios.get(`/games_apit/${category}`, { params });

    if (response.data.success) {
      this.totalGames = response.data.total;

      // Yeni oyunları alın ve tekrarları kontrol edin
      const newGames = response.data.data.filter(
        (newGame) =>
          !this.games.some((existingGame) => existingGame._id === newGame._id)
      );

      // Eğer reset isteniyorsa oyunları sıfırla
      if (reset) {
        this.games = newGames;
      } else {
        this.games.push(...newGames);
      }
    } else {
      console.error("Failed to fetch games:", response.data.message);
    }
  } catch (error) {
    console.error("Error fetching games:", error);
  }
},

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  },

  selectProvider(provider) {
    if (this.selectedProvider?.provider_code === provider.provider_code) {
      this.selectedProvider = null; // Seçimi kaldır
    } else {
      this.selectedProvider = provider; // Provider'ı seç
    }

    this.offset = 0; // Offset sıfırla
    this.games = []; // Oyun listesini sıfırla
    this.fetchGames(true); // Yeni oyunları yükle
    this.isDropdownOpen = false; // Dropdown'u kapat
  },

  launchGame(game) {
    this.$router.push({ name: 'GameStart', params: { id: game.game_code } });
    },

  loadMoreGames() {
  if (this.games.length < this.totalGames) {
    this.offset += this.gamesToShow; // Offset artır
    this.fetchGames(); // Yeni oyunları yükle
  }
}

},



    

}
</script>

<style scoped>
    .home {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 45px 10px;
    }

    .home .home-banner {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        
    }

    .home .home-buttons {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin: 20px 0;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .home .button-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
        margin: 0 10px;
    }

    .home .button-item img {
        width: 45px;
        height: 45px;
        object-fit: cover;
    }

    .home .button-item span {
        margin-top: 10px;
        text-align: center;
        font-family: 'Rubik';
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
    }

    .provider-filter {
        position: absolute;
    right: 10px;
    /* top: 20px; */
    z-index: 1000;
    margin-top: 200px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: rgba(13, 49, 78, 0.36);
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
  text-align: left;
}

.dropdown-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.dropdown-text {
  flex: 1; /* Metni genişletip ortalar */
  text-align: left;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
}


.dropdown-menu {
    max-height: 300px;
  overflow-y: auto;
  background-color: #021726;
  color: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-item input {
  margin-right: 10px;
}

.dropdown-item:hover {
  background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
}

.provider-count {
  font-size: 14px;
  opacity: 0.8;
}

.dropdown-search {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgba(13, 49, 78, 0.36);
  border-bottom: 1px solid #444;
}

.search-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.search-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
  padding: 5px 0;
}

.search-input::placeholder {
  color: #888;
}


.game-search-container {
  position: relative;
  margin: 20px 0;
  width: 100%;
}

.game-search-box {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: rgba(13, 49, 78, 0.575);
  border-radius: 8px;
  width: 100%;
  margin-top: 30px;
  
  color: white;
}

.game-search-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.game-search-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
}

.game-search-input::placeholder {
  color: #aaa;
  font-size: 14px;
}

/* Search Results Container */
.game-search-results {
  background-color: rgba(13, 49, 78, 0.36);
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  position: relative;
}

/* Close Button */
.game-search-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

/* Search Results Header */
.game-search-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.game-search-header img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Search Results Content */
.game-search-content {
  display: flex;
  gap: 15px;
  overflow-x: auto; /* Yatay kaydırma */
  white-space: nowrap; /* Tek sıra */
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.game-search-item {
  flex-shrink: 0; /* Kaydırılabilir öğe */
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #01344e;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.game-search-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.game-search-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

/* Search Message */
.game-search-message {
  font-size: 14px;
  color: #888;
  margin-top: 10px;
  text-align: center;
}

/* Recent Searches */
.game-search-recent-searches {
  margin-top: 20px;
}

.game-search-recent-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: white;
}

.game-search-recent-header img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.game-search-recent-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.game-search-recent-item {
  background-color: #01344e;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.game-search-recent-item:hover {
  background-color: #005d7e;
  transform: scale(1.05);
}

.game-search-list {
  display: flex;
  gap: 15px;
}




    .game-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
}

.games-list {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(146px, 1fr)); /* Ekran boyutuna göre dinamik sütun */
  justify-content: center;
  width: 100%;
  
}

.game-item {
  position: relative;
  
  height: auto;
}



.game-banner {
  width: 165px;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

@media (max-width: 768px) {
  
  .game-banner {
    width: 130px; /* Grid sistemine göre genişlik otomatik ayarlanır */
    height: auto;
  }
  .games-list {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(146px, 1fr)); /* Ekran boyutuna göre dinamik sütun */
  justify-content: center;
  width: 100%;
  
}

}

.see-more-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.see-more-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 75%);
  filter: drop-shadow(0px 4px 25px rgba(1, 230, 169, 0.15));
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.see-more-button:hover {
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 75%);
}

/* Mobil düzen */
@media (max-width: 768px) {
  .games-list {
    grid-template-columns: repeat(3, 1fr); /* Mobilde 3 sütun */
  }
}
    @media only screen and (max-width: 1600px) {
        .home .home-banner,
        .home .home-featured,
        .home .home-buttons,
        .home .home-games {
            width: 100%;
        }
        .home .home-buttons {
            flex-wrap: nowrap;
        }
    }

    @media only screen and (max-width: 950px) {
        .home {
            padding: 25px 10px 443px 10px;
        }

        .home .home-buttons {
            flex-wrap: nowrap;
        }

        

        .home .button-item {
            width: 90px;
            margin: 0 5px;
        }
    }
</style>
