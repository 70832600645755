<template>
  
    <aside 
    
    :class="['sidebar', { 'sidebar-closed': isSidebarClosed, 'mobile-hidden': isMobile && !isSidebarOpen, 'mobile-horizontal': isMobile && isSidebarClosed }]"
    :style="{ transform: isSidebarClosed && isMobile ? 'translateX(-300px)' : 'translateX(0)' }"
    >
   

    <!-- Free Money Image Button -->
    <div class="img-button">
      <a @click="navigateTo('/rewards')">
        <img v-if="!isSidebarClosed" src="@/assets/img/sidebar/freemoney.png" alt="Free Money" />
        <img v-else src="@/assets/img/sidebar/freec.png" alt="Free Money Closed" />
      </a>
    </div>

      <!-- Sidebar Butonları -->
      <div class="sidebar-buttons">
      <li class="button-item" @click="navigateTo('/')">
        <img src="@/assets/img/sidebar/1.png" alt="Home Icon" class="button-icon home-icon" />
        <span class="button-text">HOME</span>
      </li>
      <li class="button-item" @click="navigateTo('/rivo-vault')">
        <img src="@/assets/img/sidebar/4.png" alt="Rivo Vault Icon" class="button-icon rivo-vault-icon" />
        <span class="button-text">RIVO VAULT</span>
      </li>
      </div>
     <!-- Jackpot Image Button -->
     <div class="img-button">
      <a @click="openJackpotModal">
  <img v-if="!isSidebarClosed" src="@/assets/img/sidebar/jackpot.png" alt="Jackpot" />
  <img v-else src="@/assets/img/sidebar/jackpotc.png" alt="Jackpot Closed" />
</a>
    </div>

    <!-- Modal Jackpot -->
    <ModalJackpot v-if="showModal" :showModal="showModal" @close="closeModal" />
 

    <!-- Modal Jackpot -->
  


    <div class="sidebar-buttons">
      <li class="button-item" @click="navigateTo('/slot')">
        <img src="@/assets/img/sidebar/5.png" alt="Casino Icon" class="button-icon casino-icon" />
        <span class="button-text">CASINO</span>
      </li>
      <li class="button-item" @click="navigateTo('/slot')">
        <img src="@/assets/img/sidebar/14.png" alt="Live Casino Icon" class="button-icon live-casino-icon" />
        <span class="button-text">LIVE CASINO</span>
      </li>
      <li class="button-item" @click="navigateTo('/sports')">
        <img src="@/assets/img/sidebar/6.png" alt="Sports Icon" class="button-icon sports-icon" />
        <span class="button-text">SPORTS</span>
      </li>
      <li class="button-item" @click="navigateTo('/e-sports')">
        <img src="@/assets/img/sidebar/7.png" alt="E-Sports Icon" class="button-icon esports-icon" />
        <span class="button-text">E-SPORTS</span>
      </li>
      <li class="button-item" @click="navigateTo('/horse-racing')">
        <img src="@/assets/img/sidebar/8.png" alt="Horse Racing Icon" class="button-icon horse-racing-icon" />
        <span class="button-text">HORSE RACING</span>
      </li>
    </div>
      <div class="img-button">
      <a @click="navigateTo('/rewards')">
        <img v-if="!isSidebarClosed" src="@/assets/img/sidebar/promo.png" alt="Promo" />
        <img v-else src="@/assets/img/sidebar/promoc.png" alt="Promo Closed" />
      </a>
    </div>
    <div class="sidebar-buttons">
      <li class="button-item" @click="navigateTo('/promotions')">
        <img src="@/assets/img/sidebar/9.png" alt="Promotions Icon" class="button-icon promotions-icon" />
        <span class="button-text">PROMOTIONS</span>
      </li>
      <li class="button-item" @click="navigateTo('/affiliates')">
        <img src="@/assets/img/sidebar/10.png" alt="Refer & Earn Icon" class="button-icon refer-earn-icon" />
        <span class="button-text">REFER & EARN</span>
      </li>
      <li class="button-item" @click="navigateTo('/rank')">
        <img src="@/assets/img/sidebar/11.png" alt="Rank System Icon" class="button-icon rank-system-icon" />
        <span class="button-text">RANK SYSTEM</span>
      </li>
      <li class="button-item" @click="navigateTo('/news')">
        <img src="@/assets/img/sidebar/13.png" alt="News Icon" class="button-icon news-icon" />
        <span class="button-text">NEWS</span>
      </li>
      <li class="button-item" @click="navigateTo('/helper')">
        <img src="@/assets/img/sidebar/12.png" alt="Customer Services Icon" class="button-icon customer-services-icon" />
        <span class="button-text">CUSTOMER SERVICES</span>
      </li>
    </div>
    <div class="img-button">
      <a @click="navigateTo('/rewards')">
        <img v-if="!isSidebarClosed" src="@/assets/img/sidebar/rakeclub.png" alt="Rake Club" />
        <img v-else src="@/assets/img/sidebar/cc.png" alt="Rake Club Closed" />
      </a>
    </div>
    <div class="sidebar-buttons">
      <li class="button-item" @click="navigateTo('/promotions')">
        <img src="@/assets/img/sidebar/15.png" alt="Promotions Icon" class="button-icon promotions-icon" />
        <span class="button-text">Telegram</span>
      </li>
      <li class="button-item" @click="navigateTo('/refer-earn')">
        <img src="@/assets/img/sidebar/16.png" alt="Refer & Earn Icon" class="button-icon refer-earn-icon" />
        <span class="button-text">WhatsAPP</span>
      </li>
      <li class="button-item" @click="navigateTo('/rank-system')">
        <img src="@/assets/img/sidebar/17.png" alt="Rank System Icon" class="button-icon rank-system-icon" />
        <span class="button-text">X</span>
      </li>
      <li class="button-item" @click="navigateTo('/news')">
        <img src="@/assets/img/sidebar/18.png" alt="News Icon" class="button-icon news-icon" />
        <span class="button-text">TikTok</span>
      </li>
      
    </div>
    <div class="sidebar-buttons">
      <li class="button-item" v-on:click="modalsSetShow('Privacy')">
        <img src="@/assets/img/sidebar/19.png" alt="Promotions Icon" class="button-icon promotions-icon" />
        <span class="button-text">ABOUT US</span>
      </li>
      <li class="button-item" v-on:click="modalsSetShow('Faq')  ">
        <img src="@/assets/img/sidebar/20.png" alt="Refer & Earn Icon" class="button-icon refer-earn-icon" />
        <span class="button-text">HELP CENTER</span>
      </li>
      <li class="button-item" v-on:click="modalsSetShow('Privacy')">
        <img src="@/assets/img/sidebar/21.png" alt="Rank System Icon" class="button-icon rank-system-icon" />
        <span class="button-text">LICENCE</span>
      </li>
      <li class="button-item" v-on:click="modalsSetShow('Fair')">
        <img src="@/assets/img/sidebar/22.png" alt="News Icon" class="button-icon news-icon" />
        <span class="button-text">FAIRNESS</span>
      </li>
      
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModalJackpot from '@/components/modals/ModalJackpot.vue';

export default {
  data() {
    return {
      showModal: false, // Modal görünürlüğünü kontrol ederiz
      activeCategory: '', // Hangi kategori açık ise onu takip ediyoruz
      isMobile: false, // Mobil cihaz olup olmadığını kontrol ederiz
      isSidebarOpen: false, // Sidebar'ın mobilde açık olup olmadığını kontrol ederiz
      
    };
  },
  components: {
    ModalJackpot,
  },
  computed: {
    ...mapGetters(['isSidebarClosed']) // Vuex'ten sidebar'ın kapalı olup olmadığını alıyoruz
  },
  watch: {
    // Eğer Vuex'teki sidebar durumu mobilde otomatik açılmaya neden oluyorsa,
    // bu watch ile Vuex durumunu gözlemleyip kontrol edebiliriz
    isSidebarClosed(newVal) {
      if (this.isMobile) {
        this.isSidebarOpen = !newVal;
      }
    }
  },
  mounted() {
  this.checkIfMobile(); 
  window.addEventListener('resize', this.checkIfMobile); 

  if (this.isMobile) {
    // Mobilde sidebar'ı kapalı olarak başlatıyoruz
    this.$store.commit('toggleSidebar', true); 
  }
},
  methods: {
    handleJackpotClick() {
    console.log('Jackpot image clicked1!');
    this.$emit('openJackpotModal');  // Event gönderimi
  },
    ...mapActions(['toggleSidebar', 'toggleSidebarMobile','openJackpotModal','modalsSetShow']),
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768; // 768px altındaki ekranlar mobil sayılır
      if (this.isMobile) {
        this.isSidebarOpen = false; // Mobilde sidebar varsayılan olarak kapalı
      }
    },
    
    toggleCategory(category) {
      this.activeCategory = this.activeCategory === category ? '' : category;
    },

    navigateTo(route) {
      this.$router.push(route);
    },
    toggleMobileSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen; // Mobilde sidebar'ı açıp kapatır
      this.toggleSidebarMobile(); // Vuex'teki durumu da günceller
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  
};
</script>

<style scoped>
.sidebar {
  background: linear-gradient(180deg, #062641 0%, #031422 140%);
  position: fixed; /* Sidebar'ı ekrana sabitler */
  top: 71px;
  bottom: 0px;
  left: 0px;
  width: 16.375rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* İçeriğin kaymasını sağlar */
  height: calc(100vh - 81px); /* Sidebar yüksekliğini ayarlar */
  box-shadow: rgba(13, 19, 28, 0.4) 6px 4px 10px;
  z-index: 10;
  outline: rgba(255, 255, 255, 0.06) solid 1px;
  transition: transform 0.3s ease; /* Smooth açılma/kapanma */
}

.sidebar-closed {
  width: 4.5rem;
  overflow: hidden;
}

.mobile-hidden {
  
}

@media (max-width: 768px) {
  .sidebar {
    width: 65%; /* Mobilde varsayılan genişlik */
    z-index: 999;
  }

  .sidebar-closed {
    transform: translateX(-100%); /* Kapalıyken görünmez */
  }

  .sidebar-open {
    transform: translateX(0); /* Açıldığında görünür yap */
  }
}


/* İmg butonlar sabit üstte olacak */
.img-button {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;

  padding: 5px 0;
}

.img-button img {
  width: 56px;
  height: 56px;
}

/* Yatay kaydırma çubuğu görünümünü gizleyebilirsin */
.mobile-horizontal .sidebar-buttons::-webkit-scrollbar {
  display: none; /* Kaydırma çubuğunu gizle */
}

.sidebar-toggle {
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  border-radius: 5px;
  padding: 5px 7px 5px;
}

.img-button {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  
}

.img-button img {
  width: 16.375rem;
  height: auto;
  transition: transform 0.3s ease;
}

.sidebar-closed .img-button img {
  transform: translateX(5.75rem);
}

.sidebar-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 sütun yapısı */
  gap: 10px;
  padding: 0 1.0375rem;
}
.sidebar-closed .sidebar-buttons {
  display: block; /* Tek sütun haline gelir */
}

/* Sidebar kapalı olduğunda butonların boyutları küçülür */
.sidebar-closed .button-item {
  width: 100%; /* Tam genişlikte olur */
 
}

.button-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px;
  height: 2.3125rem;
  border-radius: 13px;
  border: 1px solid #1b90d54f;
  background: linear-gradient(100deg, rgba(0, 255, 194, 0.1) 5%, rgba(0, 255, 194, 0.07) 30%, rgba(0, 255, 194, 0.06) 50%, rgba(0, 0, 0, 0) 80%), rgba(3, 16, 27, 0.32);
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.button-icon {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  filter: drop-shadow(0 0 10px rgba(0, 255, 71, 0.7)); /* Fosforlu gölge */
  transition: filter 0.3s ease;
}

.button-item:hover .button-icon {
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 1)); /* Mouse üstüne gelince gölge beyaz */
}

.button-text {
  font-family: 'Rubik';
  font-size: 10px;
  font-weight: 900;
  background: linear-gradient(180deg, #ffffff 1.75%, rgba(108, 255, 211, 0.85) 35%, rgba(0, 255, 71, 0.55) 70.93%, #7bff90 95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  filter: drop-shadow(0px 0px 10px rgba(255, 10, 10, 0.25));
  transition: opacity 0.3s ease;
}
.sidebar-closed .img-button img {
  width: 56px;
  height: 56px;
  transform: translateX(0); /* Taşmayı önlemek için */
}

  
.sidebar-closed .button-text {
  opacity: 0; /* Sidebar kapandığında yazılar gizlenir */
}
/* Her ikon için özel shadow ayarları */
.home-icon {
  filter: drop-shadow(0 0 10px rgba(0, 255, 71, 0.7));
}

.rivo-vault-icon {
  filter: drop-shadow(0 0 10px rgba(255, 251, 0, 0.7));
}

.casino-icon {
  filter: drop-shadow(0 0 20px  rgba(255, 145, 0, 0.7));
  transition: filter 0.3s ease;
}

.live-casino-icon {
  filter: drop-shadow(0 0 10px rgba(255, 0, 0, 0.7));
}

.sports-icon {
  filter: drop-shadow(0 0 10px rgba(21, 255, 0, 0.7));
}

.esports-icon {
  filter: drop-shadow(0 0 10px rgba(97, 248, 66, 0.7));
}

.horse-racing-icon {
  filter: drop-shadow(0 0 10px rgba(0, 132, 255, 0.7));
}

.promotions-icon {
  filter: drop-shadow(0 0 10px rgba(0, 195, 255, 0.7));
}

.refer-earn-icon {
  filter: drop-shadow(0 0 10px rgba(204, 0, 245, 0.7));
}

.rank-system-icon {
  filter: drop-shadow(0 0 10px rgba(255, 0, 0, 0.7));
}

.news-icon {
  filter: drop-shadow(0 0 10px rgba(55, 0, 255, 0.7));
}

.customer-services-icon {
  filter: drop-shadow(0 0 10px rgba(167, 100, 0, 0.7));
}
.img-button {
  cursor: pointer;
}
</style>
