<template>
    <div class="admin-user-notification">
      <div class="notification-title">
        <span class="gradient-green">SEND NOTIFICATION</span>
      </div>
      <div class="notification-form">
        <div class="form-group">
          <label for="notification-title">Title</label>
          <input type="text" v-model="notificationTitle" id="notification-title" />
        </div>
        <div class="form-group">
          <label for="notification-message">Message</label>
          <textarea v-model="notificationMessage" id="notification-message"></textarea>
        </div>
        <button @click="sendNotification" class="send-button">
          <div class="button-inner">SEND</div>
        </button>
      </div>
      <div v-if="successMessage" class="success-message">
        {{ successMessage }}
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  export default {
    name: 'AdminUserNotification',
    data() {
      return {
        notificationTitle: '',
        notificationMessage: '',
        successMessage: ''
      };
    },
    computed: {
        ...mapGetters([
                'modalsData'
            ]),
    },
    methods: {
      async sendNotification() {
        try {
          const userId = this.modalsData.user._id;
          const response = await axios.post('http://192.168.1.111:5000/notices/individual', {
            title: this.notificationTitle,
            message: this.notificationMessage,
            recipientId: userId
          });
          
          if (response.status === 201) {
            this.successMessage = 'Notification sent successfully!';
            this.notificationTitle = '';
            this.notificationMessage = '';
          }
        } catch (error) {
          console.error('Error sending notification:', error);
          this.successMessage = 'Error sending notification. Please try again.';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .admin-user-notification {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .notification-title {
    text-align: center;
    font-size: 32px;
    font-weight: 900;
    color: #00ffc2;
    margin-bottom: 20px;
  }
  
  .notification-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    color: #bbbfd0;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: #1a4f63;
    color: #fff;
    border: none;
    font-size: 14px;
  }
  
  textarea {
    resize: vertical;
    height: 100px;
  }
  
  .send-button {
    width: 100px;
    height: 40px;
    margin-top: 15px;
    background-color: #00ffc2;
    color: #072435;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #00aa6d;
  }
  
  .success-message {
    margin-top: 20px;
    font-size: 16px;
    color: #00ffc2;
  }
  </style>
  