<template>
    <div class="game-start-container">
      <!-- Üst Kısım: Oyun Başlığı, Sağdaki Bilgiler ve Kontroller -->
    
  
      <!-- İframe Alanı -->
      <div class="iframe-container">
  <!-- Loading Placeholder -->
  <div v-if="isLoading" class="loading-placeholder">
    <p>Loading...</p>
   
  </div>

  <!-- Iframe -->
  <iframe
    v-if="gameUrl"
    :src="gameUrl"
    frameborder="0"
    allowfullscreen
    class="game-iframe"
    @load="onIframeLoad"
    @error="onIframeError"
  ></iframe>
</div>


      <div class="game-header">
        <div class="game-info">
          <p class="game-name">{{ gameDetails.game_name }}</p>
          <p class="provider-code">{{ gameDetails.provider_code }}</p>
        </div>
        <div class="game-stats">
          <span class="views">👁 {{ gameDetails.views }} </span>
          <span class="likes">❤️ {{ gameDetails.likes }}</span>
          <button @click="likeGame" class="like-button">👍 Like</button>
          
          <button @click="toggleFullscreen" class="control-button">⛶ Fullscreen</button>
        </div>
      </div>
  
      <!-- Popüler Oyunlar -->
      <div class="most-popular-games">
        <h3>Most Popular Games</h3>
        <div class="popular-games-list">
          <div
            v-for="(game, index) in mostPopularGames"
            :key="index"
            class="popular-game-item"
          >
            <img :src="game.banner" alt="Game Banner" class="popular-game-banner" />
            
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import axios from "axios";
  import { mapGetters } from "vuex";
  
  export default {
    name: "GameStart",
    data() {
      return {
        gameDetails: {}, // Gelen oyun detayları
        gameUrl: "", // API'den dönen iframe URL'si
        isLoading: false, // Yükleme durumu
        mostPopularGames: [], // Popüler oyunlar
        isTheaterMode: false, // Tiyatro modu durumu
      };
    },
    computed: {
      ...mapGetters(["authUser"]),
    },
    async mounted() {
      this.isLoading = true;
  
      try {
        const gameId = this.$route.params.id;
  
        // Oyunun detaylarını al
        const gameResponse = await axios.get(`/games/${gameId}`);
        if (gameResponse.data.success) {
          this.gameDetails = gameResponse.data.data;
  
          // Distributor kontrolü ve API çağrısı
          const distributor = this.gameDetails.distributor.toLowerCase();
          if (distributor === "nexus") {
            await this.launchNexusGame();
          } else if (distributor === "drakon") {
            await this.launchDrakonGame();
          } else {
            throw new Error("Unsupported game distributor.");
          }
  
          // Popüler oyunları yükle
          this.loadMostPopularGames();
        }
      } catch (error) {
        console.error("Error loading game:", error.message);
      } finally {
        this.isLoading = false;
      }
    },
    methods: {
        onIframeLoad() {
      this.isLoading = false; // Iframe yüklendiğinde loading durumunu kapat
    },
    onIframeError() {
      console.error("Iframe yüklenirken bir hata oluştu."); // Hata durumunda log yaz
    },
      async launchNexusGame() {
        try {
          const response = await axios.post("/gold_api", {
            method: "game_launch",
            user_code: this.authUser?.user?._id,
            provider_code: this.gameDetails.provider_code,
            game_code: this.gameDetails.game_code,
            lang: "en",
          });
  
          if (response.data.status === 1 && response.data.launch_url) {
            this.gameUrl = response.data.launch_url;
          } else {
            throw new Error(response.data.msg || "Failed to launch game.");
          }
        } catch (error) {
          console.error("Error launching Nexus game:", error.message);
        }
      },
      async launchDrakonGame() {
    try {
      const response = await axios.post("/drakon_api", {
        method: "game_launch",
        user_code: this.authUser?.user?.phone,
        game_code: this.gameDetails.game_code,
        lang: "en",
      });

      if (response.data.status === 1 && response.data.game_url) {
        this.gameUrl = response.data.game_url;
        this.isLoading = true; // Iframe yüklenmeye başlarken loading durumunu true yap
      } else {
        throw new Error(response.data.msg || "Failed to launch game.");
      }
    } catch (error) {
      console.error("Error launching Drakon game:", error.message);
      this.isLoading = false; // Hata durumunda loading durumunu false yap
    }
  },
      async loadMostPopularGames() {
        try {
          const response = await axios.get("/games_most?featured=1&limit=10");
          if (response.data.success) {
            this.mostPopularGames = response.data.data;
          }
        } catch (error) {
          console.error("Error loading most popular games:", error.message);
        }
      },
      toggleTheaterMode() {
        this.isTheaterMode = !this.isTheaterMode;
        const iframeContainer = document.querySelector(".iframe-container");
        if (this.isTheaterMode) {
          iframeContainer.classList.add("theater-mode");
        } else {
          iframeContainer.classList.remove("theater-mode");
        }
      },
      toggleFullscreen() {
        const iframeContainer = document.querySelector(".iframe-container");
        if (iframeContainer.requestFullscreen) {
          iframeContainer.requestFullscreen();
        } else if (iframeContainer.webkitRequestFullscreen) {
          iframeContainer.webkitRequestFullscreen();
        } else if (iframeContainer.msRequestFullscreen) {
          iframeContainer.msRequestFullscreen();
        }
      },
      likeGame() {
      // Oyunu beğenme işlemi
      this.gameDetails.likes++;
    },
    },
  };
  </script>
  
  <style scoped>
  .game-start-container {
    padding: 20px;
    text-align: center;
  }
  
  .game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(180deg, #062641 0%, #031422 140%);
  padding: 30px;
    margin-top: -15px;
    border-radius: 10px;
    color: white;
    gap: 15px;
}

.game-info {
  text-align: left;
  margin-left: -10px;

}

.game-name {
  font-size: 16px;
  font-weight: bold;
}

.provider-code {
  font-size: 10px;
  color: #fff;
}

.game-stats {
  display: flex;
  gap: 10px;
  align-items: center;
}

.views,
.likes {
  font-size: 14px;
}

.like-button {
  background-color: #ff4757;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.like-button:hover {
  background-color: #e84141;
}
  
  .game-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .game-controls {
    display: flex;
    gap: 10px;
  }
  
  .control-button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .control-button:hover {
    background-color: #0056b3;
  }
  
  .iframe-container {
    width: 100%;
    height: calc(85vh - 170px);
    position: relative;
  }
  

  .loading-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.loading-placeholder img {
  max-width: 200px;
  margin-top: 10px;
}

.loading-placeholder p {
  font-size: 18px;
  color: #666;
  margin: 0;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: calc(85vh - 170px);
}
  .game-iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
  }
  
  .theater-mode {
    width: 100%;
    height: 100vh;
    background-color: #000;
  }
  
  .most-popular-games {
    margin-top: 20px;
    text-align: left;
    color: #fff;
  }
  
  .most-popular-games h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .popular-games-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .popular-game-item {
    width: 156px;
    text-align: center;
  }
  
  .popular-game-banner {
    width: 156px;
    height: 180px;
    
    border-radius: 8px;
  }
  
  .popular-game-name {
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  </style>
  