const state = {
    isJackpotModalOpen: false,
  };
  
  const mutations = {
    OPEN_JACKPOT_MODAL(state) {
      state.isJackpotModalOpen = true;
    },
    CLOSE_JACKPOT_MODAL(state) {
      state.isJackpotModalOpen = false;
    },
  };
  
  const actions = {
    openJackpotModal({ commit }) {
      commit('OPEN_JACKPOT_MODAL');
    },
    closeJackpotModal({ commit }) {
      commit('CLOSE_JACKPOT_MODAL');
    },
  };
  
  const getters = {
    isJackpotModalOpen: state => state.isJackpotModalOpen,
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  