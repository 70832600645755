<template>
    <div class="news-page">
      <h1>Platform News</h1>
      <div class="news-list">
        <div class="news-item" v-for="news in newsList" :key="news.title">
            
            <img :src="news.image" alt="News Image" class="news-image" />
          <h2>{{ news.title }}</h2>
          <p>{{ news.summary }}</p>
          <button class="detail-btn" @click="openDetail(news)">Detail</button>
        </div>
      </div>
  
      <!-- Modal for News Details -->
      <div v-if="showModal" class="modal" @click="closeModal">
        <div class="modal-content" @click.stop>
          <img :src="selectedNews.image" alt="News Image" class="modal-news-image" />
          <h2>{{ selectedNews.title }}</h2>
          <p>{{ selectedNews.fullContent }}</p>
          <button class="modal-close" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "NewsPage",
    data() {
      return {
        showModal: false,
        selectedNews: {},
        newsList: [] // Dinamik veri
      };
    },
    mounted() {
      this.fetchNews(); // Komponent yüklendiğinde haber verilerini çek
    },
    methods: {
      async fetchNews() {
        try {
          const response = await axios.get("https://play.rivobit.com/news"); // Backend'den haberleri çek
          this.newsList = response.data.map((news) => ({
            title: news.title,
            summary: news.subtitle,
            fullContent: news.content,
            image: news.img
          }));
        } catch (error) {
          console.error("Haberler alınırken hata oluştu:", error);
        }
      },
      openDetail(news) {
        this.selectedNews = news;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .news-page {
   
    color: #fff;
    font-family: 'Rubik', sans-serif;
    text-align: center;
  }
  
  h1 {
    font-size: 28px;
    font-weight: 900;
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .news-list {
    display: flex;
    
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  
  .news-item {
    width: 300px;
    
    background: radial-gradient(100% 100% at 50% -30%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(255deg, #07263d 0%, #07243a 100%);
    border-radius: 15px;
    margin: 10px;
    text-align: center;
  }
  
  .news-item h2 {
    font-size: 18px;
    font-weight: 900;
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .news-item p {
    font-size: 14px;
    color: #ffffff;
  }
  
  .detail-btn {
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    color: #fff;
    font-weight: 900;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .detail-btn:hover {
    background-color: #00aa6d;
  }
  .news-item img {
  width: 100% !important;
  height: 151px !important;
  display: block !important;
  border-radius: 15px;
  margin-bottom: 20px;
}
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    z-index: 9999999;
  }
  
  .modal-content {
    background: radial-gradient(100% 100% at 50% -30%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(255deg, #07263d 0%, #07243a 100%);
    padding: 20px;
    border-radius: 15px;
    width: 400px;
    text-align: center;
  }
  
  .modal-content h2 {
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
  }
  
  .modal-content img.modal-news-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .modal-close {
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 900;
    margin-top: 20px;
  }
  </style>
  