<template>
  <div class="rank"> <!-- Tek kök div -->
    

    <div class="rank-system">
      <div class="rank-info">
        <h2>Rank System</h2>
        <p>At RivoBIT, we value our users and reward them as they level up. The more you play, the more you earn with our exclusive rewards, including deposit bonuses and rakeback bonuses. Climb the ranks and enjoy better rewards at each level!</p>
      </div>

      <!-- Rank Levels -->
      <div class="rank-levels">
        <div class="rank-level" v-for="level in levels" :key="level.name">
          <img :src="level.icon" alt="Level Icon" class="level-icon" />
          <h4>{{ level.name }} - Level {{ level.levelRange }}</h4>
          <p>Rakeback: {{ level.rakeback * 100 }}%</p>
          <button class="detail-btn" @click="openDetail(level)">Detail</button>
        </div>
      </div>

      <!-- Modal for Level Details -->
      <div v-if="showModal" class="modal" @click="closeModal">
        <div class="modal-content" @click.stop>
          <!-- Seçilen levelin iconunu gösteriyoruz -->
          <img :src="selectedLevel.icon" alt="Selected Level Icon" class="modal-level-icon" />
          <h2>{{ selectedLevel.name }} Details</h2>
          <p><strong>Deposit Bonus:</strong> {{ selectedLevel.depositBonus }}</p>
          <p><strong>Rakeback Bonus:</strong> {{ selectedLevel.rakeback * 100 }}%</p>
          <button class="modal-close" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthButton from '@/components/AuthButton';
import AvatarImage from '@/components/AvatarImage';
import HomeBannerUser from '@/components/home/HomeBannerUser';
import HomeBannerNews from '@/components/home/HomeBannerNews';
import HomeFeaturedLink from '@/components/home/HomeFeaturedLink';
import HomeGameElement from '@/components/home/HomeGameElement';
import { mapGetters } from 'vuex';

export default {
  name: 'RankSystem',
  components: {
    AuthButton,
    AvatarImage,
    HomeBannerUser,
    HomeBannerNews,
    HomeFeaturedLink,
    HomeGameElement,
  },
  data() {
    return {
      showModal: false,
      selectedLevel: {},
      levels: [
        { name: 'Bronze', levelRange: '1-249', rakeback: 0.0005, depositBonus: '5%', icon: require('@/assets/img/ranks/bronze.svg') },
        { name: 'Silver', levelRange: '250-499', rakeback: 0.001, depositBonus: '10%', icon: require('@/assets/img/ranks/silver.svg') },
        { name: 'Gold', levelRange: '500-999', rakeback: 0.0015, depositBonus: '15%', icon: require('@/assets/img/ranks/gold.svg') },
        { name: 'Platinum', levelRange: '1000-1999', rakeback: 0.002, depositBonus: '20%', icon: require('@/assets/img/ranks/platinum.svg') },
        { name: 'Titanium', levelRange: '2000+', rakeback: 0.0025, depositBonus: '25%', icon: require('@/assets/img/ranks/titanium.svg') }
      ]
    };
  },
  computed: {
    ...mapGetters(['authUser']),
    homeGetLevel() {
      let level = Math.floor(Math.pow(this.authUser.user.xp / 1000 / 100, 1 / 3));
      return level >= 100 ? 100 : level;
    },
    homeGetLevelColor() {
      let color = '';

      if (this.homeGetLevel >= 2 && this.homeGetLevel < 26) {
        color = 'blue';
      } else if (this.homeGetLevel >= 26 && this.homeGetLevel < 51) {
        color = 'green';
      } else if (this.homeGetLevel >= 51 && this.homeGetLevel < 76) {
        color = 'orange';
      } else if (this.homeGetLevel >= 76 && this.homeGetLevel < 100) {
        color = 'red';
      } else if (this.homeGetLevel >= 100) {
        color = 'purple';
      }

      return color;
    },
    homeGetLevelBet() {
      return this.homeGetLevel >= 100 ? 2970100 : (Math.pow(this.homeGetLevel + 1, 3) - Math.pow(this.homeGetLevel, 3)) * 100;
    },
    homeGetLevelProgress() {
      return Math.floor((this.homeGetLevel >= 100 ? 2970100 * 1000 : this.authUser.user.xp - (Math.pow(this.homeGetLevel, 3) * 100 * 1000)) / 10) / 100;
    }
  },
  methods: {
    openDetail(level) {
      this.selectedLevel = level;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
.rank {
  /* Rank Page container styling */
}

.rank-banner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.detail-btn {
  background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
  color: #fff;
  font-weight: 900;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.rank-info {
  margin-top: 50px;
  font-family: 'Rubik', sans-serif;
  color: #fff;
  text-align: center;
}

.rank-info h2 {
  font-size: 20px;
  font-weight: 900;
  background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
  text-shadow: 0px 0px 25px rgba(3, 227, 171, 0.35);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
}

.rank-info p {
  font-size: 16px;
  color: #fff;
  margin-top: 40px;
}

.rank-levels {
  display: flex;
 
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 40px;
}

.rank-level {
  width: 150px;
  padding: 10px;
  margin-left: 40px;
  background: radial-gradient(100% 100% at 50% -30%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(255deg, #07263d 0%, #07243a 100%);
  border-radius: 15px;
  margin-top: 30px;
  text-align: center;
  font-family: 'Rubik', sans-serif;
}

.rank-level h4 {
  font-size: 14px;
  font-weight: 900;
  background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
  text-shadow: 0px 0px 25px rgba(3, 227, 171, 0.35);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rank-level p {
  font-size: 12px;
  font-weight: 900;
  background: linear-gradient(250deg, #c1c4c3 0%, #6e7271 100%), #717272;
  text-shadow: 0px 0px 25px rgba(52, 54, 54, 0.35);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.level-icon {
  width: 50px;
  height: 50px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: radial-gradient(100% 100% at 50% -30%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(255deg, #07263d 0%, #07243a 100%);
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  width: 400px;
}

.modal-content img.modal-level-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.modal-content h2 {
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.modal-close {
  background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  font-weight: 900;
}

.rank h3 {
  font-size: 22px;
  font-weight: 900;
  background: linear-gradient(90deg, rgb(62, 213, 207) -49.01%, rgb(0, 255, 245 / 17%) -5.08%, rgba(66, 53, 121, 0) 98.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 20px;
}
</style>
