<template>
    <div class="bonus-page">
      <!-- Filter Options -->
      <div class="filter-container">
        <button @click="filterBonuses('all')" :class="{ active: selectedCategory === 'all' }"><span>See All</span></button>
        <button @click="filterBonuses('casino')" :class="{ active: selectedCategory === 'casino' }"><span>Casino</span></button>
        <button @click="filterBonuses('live-casino')" :class="{ active: selectedCategory === 'live-casino' }"><span>Live Casino</span></button>
        <button @click="filterBonuses('sports')" :class="{ active: selectedCategory === 'sports' }"><span>Sports</span></button>
        <button @click="filterBonuses('crypto')" :class="{ active: selectedCategory === 'crypto' }"><span>Crypto</span></button>
      </div>
  
      <!-- Bonus Cards Section -->
      <div class="bonus-cards-grid">
        <div class="bonus-card" v-for="bonus in filteredBonuses" :key="bonus.id">
          <img :src="bonus.image" alt="bonus.title" class="bonus-image">
          <h2>{{ bonus.title }}</h2>
          <p>{{ bonus.description }}</p>
          <button class="claim-btn" @click="claimBonus(bonus)">Claim Now</button>
        </div>
      </div>
  
      <!-- User Claimed Bonuses Table -->
      <div class="claimed-bonuses-table">
        <h2>User Claimed Bonuses</h2>
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Bonus</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="claim in claimedBonuses" :key="claim.id">
              <td>{{ claim.user }}</td>
              <td>{{ claim.bonus }}</td>
              <td>{{ claim.date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        selectedCategory: 'all',
        bonuses: [
          { 
            id: 1, 
            title: 'Welcome Bonus', 
            category: 'casino', 
            description: 'Get a 100% bonus on your first deposit!', 
            image: require('@/assets/img/promotions/1.webp') 
          },
          { 
            id: 2, 
            title: 'Free Spins Bonus', 
            category: 'casino', 
            description: 'Get free spins on select slot games!', 
            image: require('@/assets/img/promotions/1.webp') 
          },
          { 
            id: 3, 
            title: 'Live Casino Welcome Bonus', 
            category: 'live-casino', 
            description: 'Get a special bonus for live casino games!', 
            image: require('@/assets/img/promotions/1.webp') 
          },
          { 
            id: 4, 
            title: 'Live Casino Cashback', 
            category: 'live-casino', 
            description: 'Receive cashback on your live casino losses!', 
            image: require('@/assets/img/promotions/1.webp') 
          },
          { 
            id: 5, 
            title: 'Sports Welcome Bonus', 
            category: 'sports', 
            description: 'Get a 100% bonus on your first sports bet!', 
            image: require('@/assets/img/promotions/1.webp') 
          },
          { 
            id: 6, 
            title: 'Sports Free Bet Bonus', 
            category: 'sports', 
            description: 'Get a free bet on selected sports events!', 
            image: require('@/assets/img/promotions/1.webp') 
          },
          { 
            id: 7, 
            title: 'Crypto Deposit Bonus', 
            category: 'crypto', 
            description: 'Get a 50% bonus when depositing with crypto!', 
            image: require('@/assets/img/promotions/1.webp') 
          },
          { 
            id: 8, 
            title: 'Crypto Cashback Bonus', 
            category: 'crypto', 
            description: 'Receive cashback on your crypto bets!', 
            image: require('@/assets/img/promotions/1.webp') 
          }
        ],
        filteredBonuses: [],
        claimedBonuses: [
          { id: 1, user: 'JohnDoe', bonus: 'Welcome Bonus', date: '2024-10-10' },
          { id: 2, user: 'JaneSmith', bonus: 'Crypto Deposit Bonus', date: '2024-10-11' },
          { id: 3, user: 'MikeLee', bonus: 'Free Spins Bonus', date: '2024-10-12' },
        ]
      };
    },
    methods: {
      filterBonuses(category) {
        this.selectedCategory = category;
        if (category === 'all') {
          this.filteredBonuses = this.bonuses;
        } else {
          this.filteredBonuses = this.bonuses.filter(bonus => bonus.category === category);
        }
      },
      claimBonus(bonus) {
        const newClaim = {
          id: this.claimedBonuses.length + 1,
          user: 'TestUser',
          bonus: bonus.title,
          date: new Date().toISOString().split('T')[0]
        };
        this.claimedBonuses.push(newClaim);
      }
    },
    mounted() {
      this.filteredBonuses = this.bonuses; // Show all bonuses initially
    }
  };
  </script>
  
  <style scoped>
  /* Bonus Page General Styles */
  .bonus-page {
    color: white;
  }
  
  .filter-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filter-container button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5px;
    height: 2.3125rem;
    border-radius: 13px;
    border: 1px solid #1b90d54f;
    background: linear-gradient(100deg, rgba(0, 255, 194, 0.1) 5%, rgba(0, 255, 194, 0.07) 30%, rgba(0, 255, 194, 0.06) 50%, rgba(0, 0, 0, 0) 80%), rgba(3, 16, 27, 0.32);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .filter-container button span {
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 900;
    background: linear-gradient(180deg, #ffffff 1.75%, rgba(108, 255, 211, 0.85) 35%, rgba(0, 255, 71, 0.55) 70.93%, #7bff90 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    filter: drop-shadow(0px 0px 10px rgba(255, 10, 10, 0.25));
  }
  
  /* Bonus Cards Grid */
  .bonus-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  /* Bonus Card Styles */
  .bonus-card {
    background-color: #1f233073;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .bonus-card:hover {
    transform: scale(1.05);
  }
  
  .bonus-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .bonus-card h2 {
    margin: 15px 0;
    font-size: 1.5rem;
    font-family: 'Rubik', sans-serif;
    background: linear-gradient(180deg, #ffffff 1.75%, rgba(108, 255, 211, 0.85) 35%, rgba(0, 255, 71, 0.55) 70.93%, #7bff90 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .bonus-card p {
    margin: 15px 0;
    font-size: 1rem;
    color: #ccc;
  }
  
  .claim-btn {
    background-color: #00ffc2;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .claim-btn:hover {
    background-color: #00aa6d;
  }
  
  /* Claimed Bonuses Table */
  .claimed-bonuses-table {
    margin-top: 40px;
  }
  
  .claimed-bonuses-table h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .claimed-bonuses-table table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .claimed-bonuses-table th, .claimed-bonuses-table td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .claimed-bonuses-table th {
    background-color: #1f2330;
    color: white;
  }
  
  .claimed-bonuses-table td {
    background-color: #2a2d3b;
    color: white;
  }
  </style>
  